import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import GenerationInvoices from "./../components/monthly-billing/GenerationInvoices"
import ListInvoices from "./../components/monthly-billing/ListInvoices";

export default class MonthlyBilling extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
					<PageTitle title="Facturation mensuelle" subtitle="Dashboard" className="text-sm-left mb-3" />
				</Row>
				<Row>
					{/* Generating invoices */}
					<Col md="12" sm="12">
						<GenerationInvoices title="Génération des factures"/>
					</Col>
					{/* List invoices */}
					<Col md="12" sm="12">
						<ListInvoices title="Liste des factures"/>
					</Col>
				</Row>
			</Container>
		);
	}
}
