import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import Logs from "./../components/logs/Logs";

export default class RealTime extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Container fluid className="main-content-container px-4">
				{/* Page Header */}
				<Row noGutters className="page-header py-4">
					<PageTitle title="Logs" subtitle="Dashboard" className="text-sm-left mb-3" />
				</Row>
        {/* Logs */}
				<Row>
					<Col md="12" sm="12" className="mb-4">
						<Logs title="Logs"/>
					</Col>
				</Row>
			</Container>
		);
	}
}
