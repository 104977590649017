import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import CreditsProgressDay from "../components/statistics/CreditsProgressDay";
import AccountsOverview from "./../components/statistics/AccountsOverview";
import FoldersProgress from "./../components/statistics/FoldersProgress";
import FoldersProgressDay from "../components/statistics/FoldersProgressDay";
import FoldersProgressDayPercentage from "../components/statistics/FoldersProgressDayPercentage";
import AccountsCredits from "../components/statistics/AccountsCredits";
import TurnoverMonthly from "../components/statistics/TurnoverMonthly";

export default class Statistics extends React.Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title="Statistiques"
            subtitle="Dashboard"
            className="text-sm-left mb-3"
          />
        </Row>
        {/* Accounts Overview */}
        <Row>
          <Col lg="7" md="12" sm="12" className="mb-4">
            <CreditsProgressDay />
          </Col>
          <Col lg="5" md="12" sm="12" className="mb-4">
            <AccountsOverview />
          </Col>
          <Col lg="5" md="12" sm="12" className="mb-4">
            <FoldersProgress />
          </Col>
          <Col lg="7" md="12" sm="12" className="mb-4">
            <FoldersProgressDay />
          </Col>
          <Col lg="7" md="12" sm="12" className="mb-4">
            <FoldersProgressDayPercentage />
          </Col>
          <Col lg="5" md="12" sm="12" className="mb-4">
            <TurnoverMonthly />
          </Col>
          <Col lg="12" md="12" sm="12" className="mb-4">
            <AccountsCredits />
          </Col>
        </Row>
      </Container>
    );
  }
}
