import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import RealTime from "./views/RealTime";
import Statistics from "./views/Statistics";
import Logs from "./views/Logs";
import MonthlyBilling from "./views/MonthlyBilling";
import Login from "./views/Login";
import Logout from "./views/Logout";

export default [
	{
		path: "/",
		exact: true,
		layout: DefaultLayout,
		component: () => <Redirect to="/real-time" />,
	},
	{
		path: "/real-time",
		layout: DefaultLayout,
		component: RealTime,
	},
	{
		path: "/statistics",
		layout: DefaultLayout,
		component: Statistics,
	},
	{
		path: "/logs",
		layout: DefaultLayout,
		component: Logs,
	},
	{
		path: "/monthly-billing",
		layout: DefaultLayout,
		component: MonthlyBilling,
	},
	{
		path: "/login",
		layout: Login,
		component: Login,
	},
	{
		path: "/logout",
		layout: Logout,
		component: Logout,
	},
];
