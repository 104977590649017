import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, FormInput } from "shards-react";
import axios from "axios";

import baseURL from "../components/common/axios-config";
import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/real-time/SmallStats";
import TopAccounts from "./../components/real-time/TopAccounts";

export default class RealTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallStats: this.props.smallStats,
      topAccount: [],
      date: null,
      month: null,
    };
  }

  getSmallStats() {
    axios(baseURL + "/realTime-smallStats", {
      method: "POST",
      withCredentials: true,
      data: {
        month: this.state.month,
      },
    }).then((res) => {
      if (res.data.result === true) {
        const jsonResult = res.data.data;

        this.props.smallStats[0].value = jsonResult[0].value;
        this.props.smallStats[0].oldValue = jsonResult[0].oldValue;
        this.props.smallStats[0].percentage = jsonResult[0].percentage;
        this.props.smallStats[0].increase = jsonResult[0].increase;
        this.props.smallStats[0].datasets[0].data = jsonResult[0].data;

        this.props.smallStats[1].value = jsonResult[1].value;
        this.props.smallStats[1].oldValue = jsonResult[1].oldValue;
        this.props.smallStats[1].percentage = jsonResult[1].percentage;
        this.props.smallStats[1].increase = jsonResult[1].increase;
        this.props.smallStats[1].datasets[0].data = jsonResult[1].data;

        this.props.smallStats[2].value = jsonResult[2].value;
        this.props.smallStats[2].oldValue = jsonResult[2].oldValue;
        this.props.smallStats[2].percentage = jsonResult[2].percentage;
        this.props.smallStats[2].increase = jsonResult[2].increase;
        this.props.smallStats[2].datasets[0].data = jsonResult[2].data;

        this.props.smallStats[3].value = jsonResult[3].value;
        this.props.smallStats[3].oldValue = jsonResult[3].oldValue;
        this.props.smallStats[3].percentage = jsonResult[3].percentage;
        this.props.smallStats[3].increase = jsonResult[3].increase;
        this.props.smallStats[3].datasets[0].data = jsonResult[3].data;

        this.props.smallStats[4].value = jsonResult[4].value;
        this.props.smallStats[4].oldValue = jsonResult[4].oldValue;
        this.props.smallStats[4].percentage = jsonResult[4].percentage;
        this.props.smallStats[4].increase = jsonResult[4].increase;
        this.props.smallStats[4].datasets[0].data = jsonResult[4].data;

        this.setState({
          smallStats: this.props.smallStats,
          date: new Date().toLocaleTimeString(),
        });
      }
    });
  }

  getTopAccounts() {
    axios(baseURL + "/realTime-topAccounts", {
      method: "POST",
      withCredentials: true,
      data: {
        month: this.state.month,
      },
    }).then((res) => {
      if (res.data.result === true) {
        const jsonResult = res.data.data;
        this.setState({
          topAccount: jsonResult["topAccount"],
        });
      }
    });
  }

  componentDidMount() {
    this.getSmallStats();
    this.getTopAccounts();

    this.intervalID = setInterval(
      function (element) {
        element.getSmallStats();
        element.getTopAccounts();
      },
      120000,
      this
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  onMonthChange = (e) => {
    this.setState({ month: e.target.value }, () => {
      this.getSmallStats();
      this.getTopAccounts();
    });
  };

  render() {
    return (
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <PageTitle
            title={"Temps réel " + this.state.date}
            subtitle="Dashboard"
            className="text-sm-left mb-3 d-flex"
          >
            <FormInput
              type="month"
              value={this.state.month}
              onChange={this.onMonthChange}
            />
          </PageTitle>
        </Row>
        {/* SmallStats */}
        <Row>
          {this.state.smallStats.map((stats, idx) => (
            <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
              <SmallStats
                id={`small-stats-${idx}`}
                variation="1"
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                oldValue={stats.oldValue}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
              />
            </Col>
          ))}
        </Row>
        {/* Top Accounts */}
        <Row>
          <Col md="12" sm="12" className="mb-4">
            <TopAccounts
              title="Classement des comptes"
              data={this.state.topAccount}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

RealTime.propTypes = {
  smallStats: PropTypes.array,
};

RealTime.defaultProps = {
  smallStats: [
    {
      label: "ENVOYÉS (JOURNALIER)",
      value: 0,
      percentage: "0%",
      increase: null,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Aujourd'hui",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [null, null, null, null, null, null, null],
        },
      ],
    },
    {
      label: "RETOURNÉS (JOURNALIER)",
      value: 0,
      percentage: "0%",
      increase: null,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Aujourd'hui",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [null, null, null, null, null, null, null],
        },
      ],
    },
    {
      label: "ENVOYÉS (MENSUEL)",
      value: 0,
      percentage: "0%",
      increase: null,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Ce mois-ci",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [null, null, null, null, null, null],
        },
      ],
    },
    {
      label: "RETOURNÉS (MENSUEL)",
      value: 0,
      percentage: "0%",
      increase: null,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Ce mois-ci",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [null, null, null, null, null, null],
        },
      ],
    },
    {
      label: "CRÉDITS (MENSUEL)",
      value: 0,
      percentage: "0%",
      increase: null,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Ce mois-ci",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [null, null, null, null, null, null],
        },
      ],
    },
  ],
};
