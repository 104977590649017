import React from "react";
import PropTypes from "prop-types";
import { Row, Col, FormSelect, Card, CardHeader, CardBody, CardFooter } from "shards-react";
import axios from "axios";
import Loader from "react-loader-spinner";

import baseURL from "../common/axios-config";
import Chart from "../../utils/chart";

export default class FoldersProgress extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		this.state = {
			email: [],
			emailSelected: "all",
			type: "current-week",
			chartData: this.props.chartData,
			load: false,
		};
	}

	componentDidMount() {
		const chartConfig = {
			type: "pie",
			data: this.state.chartData,
			options: {
				...{
					legend: {
						position: "bottom",
						labels: {
							padding: 25,
							boxWidth: 20,
						},
					},
					cutoutPercentage: 0,
					tooltips: {
						custom: false,
						mode: "index",
						position: "nearest",
						callbacks: {
							label: function(tooltipItems, data) {
								const dataset = data.datasets[tooltipItems.datasetIndex];
								const currentValue = dataset.data[tooltipItems.index];
								return data.labels[tooltipItems.index] + ": " + currentValue + "%";
							},
						},
					},
				},
				...this.props.chartOptions,
			},
		};
		this.StatisticsFolderProgress = new Chart(this.canvasRef.current, chartConfig);
		this.getFoldersProgress(null, null);

		axios
			.get(baseURL + "/statistics-accounts", {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.result === true) {
					this.setState({ email: res.data.data });
				}
			});
	}

	componentDidUpdate() {
		this.StatisticsFolderProgress.update();
	}

	getFoldersProgress(type, email) {
		this.setState({ load: false });
		axios(baseURL + "/statistics-foldersProgress", {
			method: "POST",
			data: {
				type: type === null ? this.state.type : type,
				email: email === null ? this.state.emailSelected : email,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ load: true });
				this.props.chartData.datasets[0].data = res.data.data;
				this.setState({ chartData: this.props.chartData });
			}
		});
	}

	render() {
		const { title } = this.props;
		return (
			<Card small className="h-100">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{title}</h6>
				</CardHeader>
				<CardBody className="d-flex py-0">
						{this.state.load === false ?
								<div className="mx-auto my-auto">
									<Loader type="ThreeDots" color="#F96332" height={100} width={100} />
								</div> : ''}
						<canvas height="220" ref={this.canvasRef} className="blog-users-by-device m-auto" style={this.state.load === false ? { display: "none" } : {}} />
				</CardBody>
				<CardFooter className="border-top">
					<Row>
						<Col>
							<FormSelect
								size="sm"
								value={this.state.type}
								style={{ maxWidth: "130px" }}
								onChange={(e) => {
									this.setState({ type: e.target.value });
									this.getFoldersProgress(e.target.value, null);
								}}
							>
								<option value="current-week">Cette semaine</option>
								<option value="today">Aujourd'hui</option>
								<option value="current-month">Ce mois-ci</option>
								<option value="current-year">Cette année</option>
							</FormSelect>
						</Col>
						<Col className="text-right">
							<FormSelect
								size="sm"
								value={this.state.emailSelected}
								style={{ maxWidth: "230px" }}
								onChange={(e) => {
									this.setState({ emailSelected: e.target.value });
									this.getFoldersProgress(null, e.target.value);
								}}
							>
								<option value="all">Tous les utilisateurs</option>
								{this.state.email.map((value, idx) => (
									<option key={idx} value={value}>
										{value}
									</option>
								))}
							</FormSelect>
						</Col>
					</Row>
				</CardFooter>
			</Card>
		);
	}
}

FoldersProgress.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
	/**
	 * The chart config object.
	 */
	chartConfig: PropTypes.object,
	/**
	 * The Chart.js options.
	 */
	chartOptions: PropTypes.object,
	/**
	 * The chart data.
	 */
	chartData: PropTypes.object,
};

FoldersProgress.defaultProps = {
	title: "État des dossiers",
	chartData: {
		datasets: [
			{
				hoverBorderColor: "#ffffff",
				data: null,
				backgroundColor: ["rgba(0,123,255,0.3)", "rgba(0,123,255,0.5)", "rgba(0,123,255,0.7)", "rgba(0,123,255,0.9)", "rgba(255,65,105,0.9)"],
			},
		],
		labels: ["Envoyé", "Ouvert", "Retourné", "Réintégré", "Erreur"],
	},
};
