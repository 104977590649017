import React from "react";
import { Container, Row, Col } from "shards-react";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";

import baseURL from "../components/common/axios-config";
import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import axios from "axios";

export default class RealTime extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: null,
			children: this.props.children,
			noNavbar: this.props.noNavbar,
			noFooter: this.props.noFooter,
		};
	}

	styleRow = {
		height: "100vh",
	};

	componentDidMount() {
		axios(baseURL + "/checkToken", {
			data: {token: localStorage.getItem('token')},
			method: "POST",
			withCredentials: true,
		}).then((res) => {
			this.setState({login: res.data.result})
		});
	}

	render() {
		if (this.state.login === null) {
			return (
				<Container fluid>
					<Row>
						<Col className="main-content p-0" sm="12" tag="main">
							<div style={this.styleRow} className="d-flex">
								<div className="mx-auto my-auto">
									<Loader type="ThreeDots" color="#F96332" height={100} width={100} />
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			);
		} else if (this.state.login === true) {
			return (
				<Container fluid>
					<Row>
						<MainSidebar />
						<Col className="main-content p-0" lg={{ size: 10, offset: 2 }} md={{ size: 9, offset: 3 }} sm="12" tag="main">
							{!this.state.noNavbar && <MainNavbar />}
							{this.state.children}
							{!this.state.noFooter && <MainFooter />}
						</Col>
					</Row>
				</Container>
			);
		} else {
			return <Redirect to="/login"></Redirect>;
		}
	}
}
