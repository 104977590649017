import React from "react";
import { Card, CardHeader, CardBody, CardFooter, Row, Form, Col, FormSelect, Button, ButtonGroup } from "shards-react";
import axios from "axios";

import baseURL from "../common/axios-config";

export default class Logs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			type: "all",
			support: "all",
			data: [],
			page: [],
			pageSelected: 0,
		};
	}

	onTypeChange = (e) => {
		this.setState({ type: e.target.value }, () => {
			this.getLogsPagination();
		});
	};

	onSupportChange = (e) => {
		this.setState({ support: e.target.value }, () => {
			this.getLogsPagination();
		});
	};

	getLogs(pageSelected) {
		this.setState({ pageSelected: pageSelected });
		axios(baseURL + "/logs-logs", {
			method: "POST",
			data: {
				support: this.state.support,
				type: this.state.type,
				page: this.state.page[pageSelected],
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ data: res.data.data });
			}
		});
	}

	getLogsPagination() {
		axios(baseURL + "/logs-logsPagination", {
			method: "POST",
			data: {
				support: this.state.support,
				type: this.state.type,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ page: res.data.data });
				this.getLogs(0);
			}
		});
	}

	componentDidMount() {
		this.getLogsPagination();
	}

	render() {
		return (
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{this.props.title}</h6>
				</CardHeader>
				<CardBody className="p-0 overflow-auto" style={{ borderBottomRightRadius: ".625rem", borderBottomLeftRadius: ".625rem" }}>
					<Form className="w-100">
						<Row className="border-bottom px-3 bg-light" form>
							<Col lg="4" md="6" sm="12">
								<FormSelect className="my-2" value={this.state.support} onChange={this.onSupportChange}>
									<option value="all">Tous les supports</option>
									<option value="SERVER">SERVER</option>
									<option value="DASHBOARD">DASHBOARD</option>
									<option value="1S2U">1S2U</option>
									<option value="HTTP">HTTP</option>
								</FormSelect>
							</Col>
							<Col lg="4" md="6" sm="12">
								<FormSelect className="my-2" value={this.state.type} onChange={this.onTypeChange}>
									<option value="all">Tous les types</option>
									<option value="SMS">SMS</option>
									<option value="SMS-V2">SMS-V2</option>
									<option value="PAGE">PAGE</option>
									<option value="MAIL">MAIL</option>
									<option value="CLIENT">CLIENT</option>
									<option value="RESEND SMS">RESEND SMS</option>
									<option value="PAYMENT">PAYMENT</option>
									<option value="ALERT">ALERT</option>
								</FormSelect>
							</Col>
						</Row>
					</Form>
					<Row className="my-auto">
						<Col sm="12">
							<table className="table mb-0">
								<thead className="bg-light">
									<tr>
										<th scope="col" className="border-0">
											#
										</th>
										<th scope="col" className="border-0">
											E-mail
										</th>
										<th scope="col" className="border-0">
											Date
										</th>
										<th scope="col" className="border-0">
											Support
										</th>
										<th scope="col" className="border-0">
											Type
										</th>
										<th scope="col" className="border-0">
											Message
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.data.map((element, idx) => (
										<tr key={idx}>
											<td>{idx + 1 + this.state.pageSelected * 20}</td>
											<td>{element.email}</td>
											<td>{element.date}</td>
											<td>{element.support}</td>
											<td>{element.type}</td>
											<td>{element.message}</td>
										</tr>
									))}
								</tbody>
							</table>
						</Col>
					</Row>
				</CardBody>
				<CardFooter className="d-flex">
					<ButtonGroup className="mb-3 mx-auto">
						<Button onClick={(e) => this.getLogs(0)} theme="white" disabled={this.state.pageSelected > 0 ? false : true}>
							Début
						</Button>
						<Button onClick={(e) => this.getLogs(this.state.pageSelected - 1)} theme="white" disabled={this.state.pageSelected > 0 ? false : true}>
							Précedent
						</Button>
						{this.state.pageSelected > 1 ? (
							<Button onClick={(e) => this.getLogs(this.state.pageSelected - 2)} theme="white">
								{this.state.pageSelected - 1}
							</Button>
						) : (
							""
						)}
						{this.state.pageSelected > 0 ? (
							<Button onClick={(e) => this.getLogs(this.state.pageSelected - 1)} theme="white">
								{this.state.pageSelected}
							</Button>
						) : (
							""
						)}
						<Button onClick={(e) => this.getLogs(this.state.pageSelected)} theme="primary">
							{this.state.pageSelected + 1}
						</Button>
						{this.state.pageSelected < this.state.page.length - 1 ? (
							<Button onClick={(e) => this.getLogs(this.state.pageSelected + 1)} theme="white">
								{this.state.pageSelected + 2}
							</Button>
						) : (
							""
						)}
						{this.state.pageSelected < this.state.page.length - 2 ? (
							<Button onClick={(e) => this.getLogs(this.state.pageSelected + 2)} theme="white">
								{this.state.pageSelected + 3}
							</Button>
						) : (
							""
						)}
						<Button onClick={(e) => this.getLogs(this.state.pageSelected + 1)} theme="white" disabled={this.state.pageSelected < this.state.page.length - 1 ? false : true}>
							Suivant
						</Button>
						<Button onClick={(e) => this.getLogs(this.state.page.length - 1)} theme="white" disabled={this.state.pageSelected < this.state.page.length - 1 ? false : true}>
							Fin
						</Button>
					</ButtonGroup>
				</CardFooter>
			</Card>
		);
	}
}
