import React from "react";
import { Card, CardHeader, CardBody } from "shards-react";

export default class TopAccounts extends React.Component {
	render() {
		return (
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{this.props.title}</h6>
				</CardHeader>
				<CardBody className="p-0 overflow-auto" style={{ borderBottomRightRadius: ".625rem",borderBottomLeftRadius: ".625rem" }}>
					<table className="table mb-0">
						<thead className="bg-light">
							<tr>
								<th scope="col" className="border-0">
									#
								</th>
								<th scope="col" className="border-0">
									E-mail
								</th>
								<th scope="col" className="border-0">
									Envoyés
								</th>
								<th scope="col" className="border-0">
									M / M-1
								</th>
								<th scope="col" className="border-0">
									Retournés
								</th>
								<th scope="col" className="border-0">
									M / M-1
								</th>
								<th scope="col" className="border-0">
									Retournés / envoyés
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.data.map((item, idx) => (
								<tr key={idx}>
									<td>{idx + 1}</td>
									<td>{item.email}</td>
									<td>
										<span>{item.send}</span>
									</td>
									<td>
										<span className={item.send_evol > 0 ? "text-success" : "text-danger"}>({item.send_evol}%)</span>
									</td>
									<td>
										<span>{item.receive}</span>
									</td>
									<td>
										<span className={item.receive_evol > 0 ? "text-success" : "text-danger"}>({item.receive_evol}%)</span>
									</td>
									<td>
										<span className={item.taux > 35 ? "text-success" : "text-danger"}>{item.taux}%</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		);
	}
}
