export default function() {
  return [
    {
      title: "Temps réel",
      to: "/real-time",
      htmlBefore: '<i class="material-icons">query_builder</i>',
      htmlAfter: ""
    },
    {
      title: "Statistiques",
      htmlBefore: '<i class="material-icons">show_chart</i>',
      to: "/statistics",
    },
    {
      title: "Logs",
      htmlBefore: '<i class="material-icons">list</i>',
      to: "/logs",
    },
    {
      title: "Facturation mensuelle",
      htmlBefore: '<i class="material-icons">euro</i>',
      to: "/monthly-billing",
    },
  ];
}
