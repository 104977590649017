import React from "react";
import { Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { Container } from "shards-react";
import axios from "axios";

import baseURL from "../components/common/axios-config";

export default class Logout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: null,
		};
	}

	componentDidMount() {
		const cookies = new Cookies();
    cookies.remove("token", { path: "/" });

		axios(baseURL + "/logout", {
			method: "POST",
			withCredentials: true,
		})
			.then((res) => {
				this.setState({ login: false });
			});
	}

	render() {
		if (this.state.login === false) {
			return <Redirect to="/login"></Redirect>;
		} else {
			return <Container></Container>;
		}
	}
}
