import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, FormInput } from "shards-react";
import axios from "axios";
import Loader from "react-loader-spinner";

import baseURL from "../common/axios-config";
import Chart from "../../utils/chart";

export default class FoldersProgressDay extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		const date = new Date();
		this.state = {
			chartData: this.props.chartData,
			month: date.getFullYear() + "-" + (parseInt(date.getMonth()) + 1 > 9 ? parseInt(date.getMonth()) + 1 : "0" + (parseInt(date.getMonth()) + 1)),
			load: false
		};
	}

	componentDidMount() {
		const chartOptions = {
			...{
				responsive: true,
				legend: {
					position: "top",
				},
				elements: {
					line: {
						// A higher value makes the line look skewed at this ratio.
						tension: 0.3,
					},
					point: {
						radius: 0,
					},
				},
				scales: {
					yAxes: [
						{
							ticks: {
								stepSize: 50,
								beginAtZero: true,
							},
						},
					],
				},
				hover: {
					mode: "nearest",
					intersect: false,
				},
				tooltips: {
					custom: false,
					mode: "nearest",
					intersect: false,
				},
			},
			...this.props.chartOptions,
		};

		this.StatisticsFoldersProgressDay = new Chart(this.canvasRef.current, {
			type: "line",
			data: this.state.chartData,
			options: chartOptions,
		});

		this.StatisticsFoldersProgressDay.render();
		this.getFoldersProgressDay();
	}

	componentDidUpdate() {
		this.StatisticsFoldersProgressDay.update();
	}

	onMonthChange = (e) => {
		this.setState({ month: e.target.value }, () => {
			this.getFoldersProgressDay();
		});
	};

	getFoldersProgressDay() {
		this.setState({load: false});
		axios(baseURL + "/statistics-foldersProgressDay", {
			method: "POST",
			data: {
				month: this.state.month,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({load: true});
				this.props.chartData.datasets[0].data = res.data.data[0];
				this.props.chartData.datasets[1].data = res.data.data[1];
				this.props.chartData.datasets[2].data = res.data.data[2];
				this.props.chartData.labels = res.data.data[4];
				this.setState({ chartData: this.props.chartData });
			}
		});
	}

	render() {
		const { title } = this.props;

		return (
			<Card small className="h-100">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{title}</h6>
				</CardHeader>
				<CardBody className="pt-0 d-flex flex-column">
					<Row className="border-bottom py-2 bg-light">
						<Col md="4" sm="12" className="d-flex mb-2 mb-sm-0">
							<FormInput type="month" value={this.state.month} onChange={this.onMonthChange} />
						</Col>
					</Row>
					<Row className="my-auto">
						{this.state.load === false ? <Col sm="12" className="d-flex">
								<div className="mx-auto my-auto">
									<Loader type="ThreeDots" color="#F96332" height={100} width={100} />
								</div>
						</Col> : ''}
						<canvas ref={this.canvasRef} style={this.state.load === false ? { display: "none" } : { maxWidth: "100% !important" }} />
					</Row>
				</CardBody>
			</Card>
		);
	}
}

FoldersProgressDay.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
	/**
	 * The chart dataset.
	 */
	chartData: PropTypes.object,
	/**
	 * The Chart.js options.
	 */
	chartOptions: PropTypes.object,
};

FoldersProgressDay.defaultProps = {
	title: "État des dossiers journaliers",
	chartData: {
		labels: null,
		datasets: [
			{
				label: "Envoyés",
				fill: "start",
				data: null,
				backgroundColor: "rgba(0,123,255,0.1)",
				borderColor: "rgba(0,123,255,1)",
				pointBackgroundColor: "#ffffff",
				pointHoverBackgroundColor: "rgb(0,123,255)",
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
			},
			{
				label: "Ouverts",
				fill: "start",
				data: null,
				backgroundColor: "rgba(255,180,0,0.1)",
				borderColor: "rgba(255,180,0,1)",
				pointBackgroundColor: "#ffffff",
				pointHoverBackgroundColor: "rgb(255,180,0)",
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
			},
			{
				label: "Retournés",
				fill: "start",
				data: null,
				backgroundColor: "rgba(23,198,113,0.1)",
				borderColor: "rgba(23,198,113,1)",
				pointBackgroundColor: "#ffffff",
				pointHoverBackgroundColor: "rgb(23,198,113)",
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
			},
		],
	},
};
