import React from "react";
import { Card, CardHeader, CardBody, Row, Col, Form, FormSelect, Button } from "shards-react";
import axios from "axios";

import baseURL from "../common/axios-config";

export default class GenerationInvoices extends React.Component {
	constructor(props) {
		super(props);
		const date = new Date();
		this.state = {
			yearArray: [date.getFullYear(), date.getFullYear() - 1, date.getFullYear() - 2],
			monthArray: [
				{ label: "Janvier", int: 0 },
				{ label: "Février", int: 1 },
				{ label: "Mars", int: 2 },
				{ label: "Avril", int: 3 },
				{ label: "Mai", int: 4 },
				{ label: "Juin", int: 5 },
				{ label: "Juillet", int: 6 },
				{ label: "Août", int: 7 },
				{ label: "Septembre", int: 8 },
				{ label: "Octobre", int: 9 },
				{ label: "Novembre", int: 10 },
				{ label: "Décembre", int: 11 },
			],
			accountArray: [],
			year: date.getFullYear(),
			month: date.getMonth(),
			account: "",
		};
	}

	componentDidMount() {
		this.getAccounts();
	}

	onYearChange = (e) => {
		this.setState({ year: e.target.value });
	};

	onMonthChange = (e) => {
		this.setState({ month: e.target.value });
	};

	onAccountChange = (e) => {
		this.setState({ account: e.target.value });
	};

	getAccounts() {
		axios(baseURL + "/monthlyBilling-getAccounts", {
			method: "POST",
			data: {
				year: this.state.year,
				month: this.state.month,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ accountArray: res.data.account });
			}
		});
	}

	getInvoice = (e) => {
		axios(baseURL + "/monthlyBilling-makeInvoice", {
			method: "POST",
			data: {
				year: this.state.year,
				month: parseInt(this.state.month) + 1 > 9 ? parseInt(this.state.month) + 1 : "0" + (parseInt(this.state.month) + 1),
				account: this.state.account,
			},
			withCredentials: true,
		}).then((res) => {
			console.log(res.data);
			if (res.data.result === true) {
				res.data.account.map((element_invoice) => {
					const linkSource = element_invoice.base64;
					const downloadLink = document.createElement("a");
					const fileName = element_invoice.name + ".pdf";

					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();
				});
			}
		});
	};

	checkInvoice = (e) => {
		axios(baseURL + "/monthlyBilling-checkInvoice", {
			method: "POST",
			data: {
				year: this.state.year,
				month: parseInt(this.state.month) + 1 > 9 ? parseInt(this.state.month) + 1 : "0" + (parseInt(this.state.month) + 1),
				account: this.state.account,
			},
			withCredentials: true,
		}).then((res) => {
			console.log(res.data);
			if (res.data.result === true) {}
		});
	};

	render() {
		return (
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{this.props.title}</h6>
				</CardHeader>
				<CardBody className="d-flex">
					<Form className="w-100">
						<Row form>
							<Col lg="2" md="6" sm="12" className="form-group">
								<FormSelect value={this.state.year} onChange={this.onYearChange}>
									{this.state.yearArray.map((element, idx) => (
										<option key={idx} value={element}>
											{element}
										</option>
									))}
								</FormSelect>
							</Col>
							<Col lg="2" md="6" sm="12" className="form-group">
								<FormSelect value={this.state.month} onChange={this.onMonthChange}>
									{this.state.monthArray.map((element, idx) => (
										<option key={idx} value={element.int}>
											{element.label}
										</option>
									))}
								</FormSelect>
							</Col>
							<Col lg="3" md="6" sm="12" className="form-group">
								<FormSelect value={this.state.account} onChange={this.onAccountChange}>
									<option value="" disabled>
										Veuillez sélectionner un compte
									</option>
									{this.state.accountArray.map((element, idx) => (
										<option key={idx} value={element}>
											{element}
										</option>
									))}
								</FormSelect>
							</Col>
							<Col lg="2" md="6" sm="12">
								<Button className="w-100" theme="accent" disabled={this.state.year && this.state.month && this.state.account ? false : true} onClick={this.checkInvoice}>
									Vérifier les données
								</Button>
							</Col>
							<Col lg="3" md="6" sm="12">
								<Button className="w-100" theme="accent" disabled={this.state.year && this.state.month && this.state.account ? false : true} onClick={this.getInvoice}>
									Générer la/les facture(s)
								</Button>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		);
	}
}
