import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, NavItem, NavLink } from "shards-react";

export default class UserActions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			name: null,
			image: null,
			visible: false,
		};

		this.toggleUserActions = this.toggleUserActions.bind(this);
	}

	componentDidMount() {
		this.setState({
			name: localStorage.getItem("name"),
			image: localStorage.getItem("image").includes('data:image') ? localStorage.getItem("image") : require("./../../../../images/avatars/default.png"),
		});
	}

	toggleUserActions() {
		this.setState({
			visible: !this.state.visible,
		});
	}

	render() {
		return (
			<NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
				<DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
					<img className="user-avatar rounded-circle mr-2" src={this.state.image} alt="Avatar" /> <span className="d-none d-md-inline-block">{this.state.name}</span>
				</DropdownToggle>
				<Collapse tag={DropdownMenu} right small open={this.state.visible}>
					<DropdownItem tag={Link} to="/logout" className="text-danger">
						<i className="material-icons text-danger">&#xE879;</i> Déconnexion
					</DropdownItem>
				</Collapse>
			</NavItem>
		);
	}
}
