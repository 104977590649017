import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, FormInput } from "shards-react";
import axios from "axios";
import Loader from "react-loader-spinner";

import baseURL from "../common/axios-config";
import RangeDatePicker from "../common/RangeDatePicker";
import Chart from "../../utils/chart";

export default class AccountsOverview extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		this.state = {
			chartData: this.props.chartData,
			startDate: null,
			endDate: null,
			minimum: 20,
			load: false,
		};
	}

	componentDidMount() {
		const chartOptions = {
			...{
				responsive: true,
				legend: {
					position: "top",
				},
				elements: {
					line: {
						// A higher value makes the line look skewed at this ratio.
						tension: 0.3,
					},
					point: {
						radius: 0,
					},
				},
				scales: {
					yAxes: [
						{
							ticks: {
								stepSize: 100,
								beginAtZero: true,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								callback: function(label) {
									return label.substring(0, 8);
								},
							},
						},
					],
				},
				hover: {
					mode: "nearest",
					intersect: false,
				},
				tooltips: {
					custom: false,
					mode: "nearest",
					intersect: false,
				},
			},
			...this.props.chartOptions,
		};

		this.StatisticsAccountsOverview = new Chart(this.canvasRef.current, {
			type: "bar",
			data: this.state.chartData,
			options: chartOptions,
		});

		this.StatisticsAccountsOverview.render();
	}

	componentDidUpdate() {
		this.StatisticsAccountsOverview.update();
	}

	callbackDate = (startDate, endDate) => {
		startDate =
			startDate.getFullYear() +
			"-" +
			(parseInt(startDate.getMonth()) + 1 > 9 ? parseInt(startDate.getMonth()) + 1 : "0" + (parseInt(startDate.getMonth()) + 1)) +
			"-" +
			(startDate.getDate() > 9 ? startDate.getDate() : "0" + startDate.getDate());

		endDate =
			endDate.getFullYear() +
			"-" +
			(parseInt(endDate.getMonth()) + 1 > 9 ? parseInt(endDate.getMonth()) + 1 : "0" + (parseInt(endDate.getMonth()) + 1)) +
			"-" +
			(endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate());

		this.setState(
			{
				startDate: startDate,
				endDate: endDate,
			},
			this.getAccountsOverview(startDate, endDate, null)
		);
	};

	getAccountsOverview(startDate, endDate, minimum) {
		this.setState({ load: false });
		axios(baseURL + "/statistics-accountsOverview", {
			method: "POST",
			data: {
				startDate: startDate === null ? this.state.startDate : startDate,
				endDate: endDate === null ? this.state.endDate : endDate,
				minimum: minimum === null ? this.state.minimum : minimum,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ load: true });
				this.props.chartData.labels = res.data.data.labels;
				this.props.chartData.datasets[0].data = res.data.data.data;
				this.setState({ chartData: this.props.chartData });
			}
		});
	}

	onMinimumChange = (e) => {
		this.setState({ minimum: e.target.value }, this.getAccountsOverview(null, null, e.target.value));
	};

	render() {
		const { title } = this.props;

		return (
			<Card small className="h-100">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{title}</h6>
				</CardHeader>
				<CardBody className="pt-0 d-flex flex-column">
					<Row className="border-bottom py-2 bg-light">
						<Col sm="6" className="d-flex mb-2 mb-sm-0">
							<RangeDatePicker callbackDate={this.callbackDate} />
						</Col>
						<Col className="ml-auto" lg="2" md="4" sm="6">
							<FormInput type="number" value={this.state.minimum} onChange={this.onMinimumChange} />
						</Col>
					</Row>
					<Row className="my-auto">
						{this.state.load === false ? <Col sm="12" className="d-flex">
								<div className="mx-auto my-auto">
									<Loader type="ThreeDots" color="#F96332" height={100} width={100} />
								</div>
						</Col> : ''}
						<canvas height="80" ref={this.canvasRef} style={this.state.load === false ? { display: "none",  maxWidth: "100% !important" } : { maxWidth: "100% !important" }} />
					</Row>
				</CardBody>
			</Card>
		);
	}
}

AccountsOverview.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
	/**
	 * The chart dataset.
	 */
	chartData: PropTypes.object,
	/**
	 * The Chart.js options.
	 */
	chartOptions: PropTypes.object,
};

AccountsOverview.defaultProps = {
	title: "Aperçu des comptes",
	chartData: {
		labels: null,
		datasets: [
			{
				label: "Envoyés",
				fill: "start",
				data: null,
				backgroundColor: "rgba(0,123,255,0.1)",
				borderColor: "rgba(0,123,255,1)",
				pointBackgroundColor: "#ffffff",
				pointHoverBackgroundColor: "rgb(0,123,255)",
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
			},
		],
	},
};
