import React from "react";
import classNames from "classnames";
import { InputGroup, DatePicker, InputGroupAddon, InputGroupText } from "shards-react";

import "../../assets/range-date-picker.css";

class RangeDatePicker extends React.Component {
	constructor(props) {
    super(props);
    
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

		this.state = {
			startDate: firstDay,
			endDate: lastDay,
		};

		this.handleStartDateChange = this.handleStartDateChange.bind(this);
		this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }
  
  componentDidMount(){
    this.props.callbackDate(this.state.startDate, this.state.endDate);
  }

	handleStartDateChange(value) {
		this.setState({ startDate: new Date(value) });
		this.props.callbackDate(new Date(value), this.state.endDate);
	}

	handleEndDateChange(value) {
    this.setState({ endDate: new Date(value) });
		this.props.callbackDate(this.state.startDate, new Date(value));
	}

	render() {
		const { className } = this.props;
		const classes = classNames(className, "d-flex", "my-auto", "date-range");

		return (
			<InputGroup className={classes}>
				<DatePicker size="sm" dateFormat="dd/MM/yyyy" selected={this.state.startDate} onChange={this.handleStartDateChange} placeholderText="Date de début" dropdownMode="select" className="text-center" />
				<DatePicker size="sm" dateFormat="dd/MM/yyyy" selected={this.state.endDate} onChange={this.handleEndDateChange} placeholderText="Date de fin" dropdownMode="select" className="text-center" />
				<InputGroupAddon type="append">
					<InputGroupText>
						<i className="material-icons">&#xE916;</i>
					</InputGroupText>
				</InputGroupAddon>
			</InputGroup>
		);
	}
}

export default RangeDatePicker;
