import React from "react";
import { Container, Row, Col, Card, CardBody, Form, FormInput, FormFeedback, Button, FormCheckbox } from "shards-react";
import axios from "axios";
import { Redirect } from "react-router-dom";

import baseURL from "../components/common/axios-config";

export default class RealTime extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
			password: "",
			cookie: false,
			valid: null,
			login: null,
		};
	}

	styleRow = {
		height: "100vh",
	};

	validateEmail(email) {
		if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		return false;
	}

	validateForm() {
		return this.state.email.length > 0 && this.validateEmail(this.state.email) && this.state.password.length > 0;
	}

	handleSubmit(event) {
		event.preventDefault();

		axios(baseURL + "/login", {
			method: "POST",
			data: {
				email: this.state.email,
				password: this.state.password,
				cookie: this.state.cookie,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				localStorage.setItem("id", res.data.data.id);
				localStorage.setItem("email", res.data.data.email);
				localStorage.setItem("name", res.data.data.name);
				localStorage.setItem("image", res.data.data.image);
				if (this.state.cookie === true) {
					localStorage.setItem("token", res.data.token);
				}
				this.setState({ valid: true });
			} else {
				this.setState({ valid: false });
			}
		});
	}

	componentDidMount() {
		axios(baseURL + "/checkToken", {
			method: "POST",
			withCredentials: true,
		}).then((res) => {
			this.setState({ login: res.data.result });
		});
	}

	render() {
		if (this.state.valid === true || this.state.login === true) {
			return <Redirect to="/"></Redirect>;
		} else {
			return (
				<Container fluid>
					<Row>
						<Col className="main-content p-0" sm="12" tag="main">
							<Row style={this.styleRow}>
								<Col lg="3" md="4" className="mx-auto my-auto">
									<Card>
										<CardBody>
											<Form>
												<Row form>
													<Col sm="12" className="form-group text-center">
														<img
															id="main-logo"
															className="d-inline-block align-top"
															style={{ maxWidth: "70px", maxHeight: "70px" }}
															src={require("../images/logo-black-512-512.png")}
															alt="WindTransfer"
														/>
													</Col>
													<Col sm="12" className="form-group">
														<h6 className="text-center">Accéder à votre compte</h6>
													</Col>
													{/* Email */}
													<Col sm="12" className="form-group">
														<label htmlFor="input-email">Adresse email</label>
														<FormInput
															invalid={this.state.valid === false ? true : false}
															type="email"
															id="input-email"
															placeholder="Adresse email"
															value={this.state.email}
															onChange={(e) => this.setState({ email: e.target.value })}
														/>
													</Col>
													{/* Password */}
													<Col sm="12" className="form-group">
														<label htmlFor="input-password">Mot de passe</label>
														<FormInput
															invalid={this.state.valid === false ? true : false}
															type="password"
															id="input-password"
															placeholder="Mot de passe"
															value={this.state.password}
															onChange={(e) => this.setState({ password: e.target.value })}
														/>
														<FormFeedback invalid="true">Adresse email ou mot de passe incorrect</FormFeedback>
													</Col>
													<Col sm="12" className="form-group d-flex">
														<FormCheckbox
															className="mx-auto"
															value={this.state.cookie}
															onChange={(e) => {
																this.setState({ cookie: e.target.checked });
															}}
														>
															Rester connecté
														</FormCheckbox>
													</Col>
													<Col sm="12" className="form-group d-flex">
														<Button className="mt-3 mx-auto btn-pill" theme="accent" disabled={!this.validateForm()} onClick={(e) => this.handleSubmit(e)}>
															Se connecter
														</Button>
													</Col>
												</Row>
											</Form>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			);
		}
	}
}
