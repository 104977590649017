import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, FormSelect } from "shards-react";
import axios from "axios";
import Loader from "react-loader-spinner";

import baseURL from "../common/axios-config";
import Chart from "../../utils/chart";

export default class TurnoverMonthly extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
		const date = new Date();
		this.state = {
			chartData: this.props.chartData,
			year: date.getFullYear(),
			yearArray: [date.getFullYear(), date.getFullYear() - 1, date.getFullYear() - 2],
			load: false,
		};
	}

	componentDidMount() {
		const chartOptions = {
			...{
				responsive: true,
				legend: {
					position: "top",
				},
				elements: {
					line: {
						// A higher value makes the line look skewed at this ratio.
						tension: 0.3,
					},
					point: {
						radius: 0,
					},
				},
				scales: {
					yAxes: [
						{
							stacked: true,
							ticks: {
								stepSize: 1000,
								beginAtZero: true,
              },
              
						},
					],
					xAxes: [
						{
							stacked: true,
						},
					],
				},
				hover: {
					mode: "nearest",
					intersect: false,
				},
				tooltips: {
					custom: false,
					mode: "nearest",
					intersect: false,
					callbacks: {
						label: function(tooltipItems, data) {
							const dataset = data.datasets[tooltipItems.datasetIndex];
							const currentValue = dataset.data[tooltipItems.index];
							return dataset.label + ": " + new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(currentValue);
						},
					},
				},
			},
			...this.props.chartOptions,
		};

		this.StatisticsTurnoverMonthly = new Chart(this.canvasRef.current, {
			type: "line",
			data: this.state.chartData,
			options: chartOptions,
		});

		this.StatisticsTurnoverMonthly.render();
		this.getTurnoverMonthly();
	}

	componentDidUpdate() {
		this.StatisticsTurnoverMonthly.update();
	}

	onYearChange = (e) => {
		this.setState({ year: e.target.value }, () => {
			this.getTurnoverMonthly();
		});
	};

	getTurnoverMonthly() {
		this.setState({ load: false });
		axios(baseURL + "/statistics-turnoverMonthly", {
			method: "POST",
			data: {
				year: this.state.year,
			},
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ load: true });
				this.props.chartData.datasets[0].data = res.data.data;
				this.setState({ chartData: this.props.chartData });
			}
		});
	}

	render() {
		const { title } = this.props;

		return (
			<Card small className="h-100">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{title}</h6>
				</CardHeader>
				<CardBody className="pt-0 d-flex flex-column">
					<Row className="border-bottom py-2 bg-light">
						<Col md="4" sm="12" className="d-flex mb-2 mb-sm-0">
							<FormSelect type="year" value={this.state.year} onChange={this.onYearChange}>
								{this.state.yearArray.map((element, idx) => (
									<option key={idx} value={element}>
										{element}
									</option>
								))}
							</FormSelect>
						</Col>
					</Row>
					<Row className="my-auto">
						{this.state.load === false ? (
							<Col sm="12" className="d-flex">
								<div className="mx-auto my-auto">
									<Loader type="ThreeDots" color="#F96332" height={100} width={100} />
								</div>
							</Col>
						) : (
							""
						)}
						<canvas ref={this.canvasRef} style={this.state.load === false ? { display: "none" } : { maxWidth: "100% !important" }} />
					</Row>
				</CardBody>
			</Card>
		);
	}
}

TurnoverMonthly.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
	/**
	 * The chart dataset.
	 */
	chartData: PropTypes.object,
	/**
	 * The Chart.js options.
	 */
	chartOptions: PropTypes.object,
};

TurnoverMonthly.defaultProps = {
	title: "Achat de crédits",
	chartData: {
		labels: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
		datasets: [
			{
				label: "Chiffre d'affaire ",
				fill: "start",
				data: null,
				backgroundColor: "rgba(0,123,255,0.1)",
				borderColor: "rgba(0,123,255,1)",
				pointBackgroundColor: "#ffffff",
				pointHoverBackgroundColor: "rgb(0,123,255)",
				borderWidth: 1.5,
				pointRadius: 0,
				pointHoverRadius: 3,
			},
		],
	},
};
