import React from "react";
import { Card, CardHeader, CardBody, CardFooter, Row, Col } from "shards-react";
import axios from "axios";

import baseURL from "../common/axios-config";

//import axios from "axios";

//import baseURL from "../common/axios-config";

export default class ListInvoices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invoices: [],
		};
	}

	componentDidMount() {
		axios(baseURL + "/monthlyBilling-getInvoices", {
			method: "POST",
			withCredentials: true,
		}).then((res) => {
			if (res.data.result === true) {
				this.setState({ invoices: res.data.invoices });
			}
		});
	}

	render() {
		return (
			<Card small className="mb-4">
				<CardHeader className="border-bottom">
					<h6 className="m-0">{this.props.title}</h6>
				</CardHeader>
				<CardBody className="p-0 overflow-auto" style={{ borderBottomRightRadius: ".625rem", borderBottomLeftRadius: ".625rem" }}>
					<Row className="border-bottom px-4 bg-light"></Row>
					<Row className="my-auto">
						<Col sm="12">
							<table className="table mb-0">
								<thead className="bg-light">
									<tr>
										<th scope="col" className="border-0">
											#
										</th>
										<th scope="col" className="border-0">
											E-mail
										</th>
										<th scope="col" className="border-0">
											Date
										</th>
										<th scope="col" className="border-0">
											Crédits
										</th>
										<th scope="col" className="border-0">
											Prix
										</th>
									</tr>
								</thead>
								<tbody>
									{this.state.invoices.map((element, idx) => (
										<tr key={idx}>
											<th>{idx}</th>
											<td>{element.email}</td>
											<td>{element.date}</td>
											<td>{element.credit}</td>
											<td>{element.price}</td>
										</tr>
									))}
								</tbody>
							</table>
						</Col>
					</Row>
				</CardBody>
				<CardFooter className="d-flex"></CardFooter>
			</Card>
		);
	}
}
